<template>
  <div>
    <div class="knives__layout">
      <app-menu />
      <knives-header />
      <slot />
      <app-footer />
      <div class="footer-bottom">
        <div class="container">
          <span>Magnum Cash & Carry @2022 {{ $t("allRightsReserved") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KnivesLayout",
  components: {
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    AppMenu: () => import("@/components/common/AppMenu.vue"),
    KnivesHeader: () => import("@/components/knives/KnivesHeader.vue"),
  },
};
</script>

<style lang="scss" scoped>
.knives__layout {
  background-color: #040707;
  padding-bottom: 20px;
}
.footer-bottom {
  background-color: #191f1f;
  margin-bottom: 0;
}
</style>
